@import "custom_variables";
@import "custom_helpers";

body {
  overflow-y: scroll;
}

.welcome {
  &-inputs {
    input {
      border: 1px solid $input-background-color;
      &.has-error {
        border: 1px $error-color solid;
      }
    }
    .checkbox label {
      padding-left: 0px;
    }
    .help-block {
      font-size: 13px;
      color: $error-color;
    }
  }

  &-footer {
    a {
      color: $link-font-color;
      &:hover {
        color: $link-font-color-hover;
      }
    }
  }
}

.btn {
  &-link {
    color: $link-font-color;
    &:hover {
      color: $link-font-color-hover;
      text-decoration: none;
    }
  }
}

.navbar-principal {
  background: $background-color;
  border-color: $border-color;

  .navbar {
    &-nav {
      li a:focus, li a:hover {
        background-color: $nav-link-background-color;
      }
      .open a {
        background-color: $nav-link-background-color;
        &:hover {background-color: $nav-link-background-color;}
      }
      .active a {
        background-color: $nav-link-background-color;
        &:hover {
          background-color: $nav-link-background-color;
        }
      }
    }
  }
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #fff;
  background: $nav-link-background-color;
}

.container.theme {
  margin-top: 100px;
}

.profile {
  &-nav {
    .user-heading {
      background: $background-color;
    }
    ul > li > a:hover, ul > li > a:focus, ul li.active a {
      border-left: 5px solid $border-color;
    }
  }
}

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .profile-nav .user-heading a img {
    width: 80px;
    height: 80px;
  }
}

.btn-toolbar {
  margin-left: 0px;
}

.profile-nav, .profile-info {
  margin-top: 0px;
}

label.error {
  color: $error-color;
  font-weight: normal;
}

.module-toolbar {
  margin-bottom: 20px;

  .checkbox {
    font-size: 14px;
    margin: 7px 0px 0px 0px;
    label {
      margin-right: 10px;
      padding-left: 16px;

      input[type=checkbox] {
        margin-left: -16px;
      }
    }
  }

  .search {
    width: 240px;
    input[type=text] {
      width: 78%;
      height: 36px;
      display: inline-block;
    }
    button {
      margin-top: 1px;
      float: right;
    }
  }
}

@media screen and (min-width: 1200px) {
  .text-button {
    width: 75%;
  }
}
@media screen and (max-width: 1199px) {
  .text-button {
    width: 72%;
  }
}
@media screen and (max-width: 990px) {
  .text-button {
    width: 85%;
  }
}

.text-button {
  float: left;
  margin-right: 10px;
}

.button-text {
  float: left;
  margin-top: 1px;
}

.fa.fa-arrow-circle-down.show-coordinates {
  color: $link-font-color-hover;
  cursor: pointer;
}

#place-map {
  width: 100%;
  height: 400px;
  background-color: lightgray;
}

#appointment-map {
  width: 100%;
  height: 280px;
  background-color: lightgray;
}

.users-list {
  width: 100%;
  min-height: 400px;
  border: 1px #e3e3e3 solid;
  border-radius: 4px;

  .empty {
    margin: 20px;
    text-align: center;
    font-size: 18px;
  }

  .attendee {
    margin: 4px;
    padding: 2px 6px 2px 2px;
    img {
      display: inline-block;
      width: 42px;
      height: 42px;
      margin-right: 6px;
      border: 0;
    }
    .delete {
      margin-top: 9px;
      margin-left: 6px;
      vertical-align: middle;
      i { color: #a94442; font-size: 20px; }
    }
    .add {
      margin-top: 9px;
      margin-left: 6px;
      vertical-align: middle;
      a { cursor: pointer; }
      i { color: $nav-link-background-color; font-size: 20px; }
    }
  }
}

.terms-list {
  width: 100%;
  min-height: 400px;
  border: 1px #e3e3e3 solid;
  border-radius: 4px;
  padding: 0 4px 0 4px;

  .term {
    padding: 10px 10px 10px 10px;
    width: 100%;
    margin: 4px 0 4px 0;

    .dates {
      .form-group {
        margin: 0;
        padding: 0;
        float: left;
        .text-danger {
          display: block;
        }
      }
      .date {
        width: 200px;
        padding-right: 10px;
      }
      .time {
        width: 140px;
        padding-right: 10px;
      }
    }
    .delete {
      margin-top: 7px;
      margin-left: 6px;
      vertical-align: middle;
      i { color: #a94442; font-size: 20px; }
    }
  }
}

.bordered-green {
  border-color: $nav-link-background-color !important;
}

.footer-links {
  text-align: center;
}

.footer-copyright {
  text-align: center;
}

img.thumb-lg {
  width: 100px;
  height: 100px;
}

img.thumb-md {
  width: 75px;
  height: 75px;
}

img.thumb-sm {
  width: 50px;
  height: 50px;
}

h4.small {
  font-size: 1.1em;
}

.team-members div {
  font-size: 12px;
}

.appointment {
  &.cancelled {
    .header {
      background-color: #e55452;
      color: #ffffff;
    }
  }
  .header {
    color: #555555;
    font-weight: bold;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #f5f5f5;
    line-height: 2.2;
    padding: 0px 10px 0px 10px;
  }
  .post-heading {
    padding: 10px 10px;
    min-height: 200px;
    height: auto;

    .image-panel {
      .comments {
        padding-top: 22px;
        font-size: 12px;
        text-decoration: underline;
        text-align: center;

        a {
          color: #555555;
        }
      }
    }

    .title-panel {
      width: 220px;
      margin-right: 10px;

      .date {
        margin-top: 3px;
      }

      .title {
        margin: 15px 0px 5px 0px;
        padding-bottom: 4px;
        font-weight: bold;
        font-size: 16px;
        border-bottom: 1px solid #eeeeee;

        a {
          color: #555555;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .description {
        font-size: 12px;
        height: 50px;
      }

      .sign-up {
        margin-top: 20px;
        button {
          width: 100%;
        }
      }
    }

    .attendees-panel {
      .title {
        text-decoration: underline;
        font-size: 12px;
        margin-bottom: 7px;
      }
      ul.attendees {
        font-size: 10px;
        margin: 0px;
        padding: 0px;
        list-style: none;
        color: #24793c;
        line-height: 1.15;
      }
    }

    .action-panel {
      width: 125px;

      .actions {
        text-align: right;

        select.form-control {
          padding: 6px 6px 3px 6px;
          height: 30px;
          font-size: 12px;
          display: inline-block;
          width: 110px;
        }
      }

      .user {
        margin-top: 35px;
        font-size: 12px;
        width: 125px;
        text-align: right;

        p {
          margin: 0;
        }

        .avatar {
          display: inline-block;
          margin: 5px 5px 5px 0;
        }

        .participating {
          color: #24793c;
        }

        .not-participating {
          color: #e55452;
        }
      }
    }
  }
}

.place-address-wrapper {
  width: 240px;
}

.attendee-email-wrapper {
  width: 450px;
}

.chat-sidebar {
  top: 55px;
  box-shadow: none;
  border-left: 1px #dddddd solid;

  &.focus {
    -webkit-transition-property: none;
    -moz-transition-property: none;
    -o-transition-property: none;
    transition-property: none;
  }

  .list-group .list-group-item {
    border-left: 0;

    i {
      color: #ffffff;
    }
    &.connected-status {
      background-color: #f5f5f5;
      color: #2ca552 !important;

      i {
        color: #2ca552;
      }
    }
    .chat-user-name {
      width: 130px;
    }
  }

  .no-friends {
    display: inline-block;
    padding: 4px 0px 5px 0px;
  }
}

.chat-sidebar-button {
  position: fixed;
  z-index: 999;
  width: 200px;
  height: 36px;
  right: 100%;
  top: 50%;
  margin-top: -18px;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);

  &.focus {
    right: -83px;
  }

  button {
    width: 100%;
  }
}

.dl-horizontal.appointment-details {
  dt {
    width: 130px;
  }
  dd {
    margin-left: 150px;
  }
}

ul.attendees {
  margin: 0px;
  padding: 0px;
  list-style: none;
  li {
    margin-bottom: 4px;
    i {
      cursor: default;
      font-size: 18px;
    }
    .img-small {
      width: 40px;
      height: 40px;
      border: 0px !important;
      background-color: #fbfbfb;
    }
    .confirmed {
      color: #24793c;
    }
    .not-confirmed {
      color: #e55452;
    }
    span {
      font-size: 12px;
    }

    span span {
      margin-top: 10px;
      display: inline-block;
    }

    .attendee-status {
      margin-top: 10px;
    }
  }
}

.img-small {
  width: 40px;
  height: 40px;
}

.widget.nearest-appointment {
  background: #222835;
  color: #ffffff;
  border-radius: 4px;

  .widget-header, .widget-body {
    border-radius: 4px;
    background: #222835;
    color: #ffffff;
    box-shadow: none;
  }
  .widget-body {
    padding-top: 15px;
    height: 69px;
  }
}

.post .post-heading {
  &.notification {
    height: auto;
    min-height: 95px;
  }

  .meta {
    .time.notification {
      margin-top: 0;
      margin-bottom: 5px;
    }

    .title.notification {
      margin-top: 0;
    }
  }

  .mark-as-read {
    margin-top: -4px;

    i {

      font-size: 20px;
      cursor: pointer;
      &:hover {
        color: #24793c;
      }
      &.read {
        color: #24793c;
        cursor: default;
      }
    }
  }
}

.media-main .info p {
  margin: 0;
}

i.badge-top-right {
  position: relative;

  &.menu-badge:after {
    content: attr(data-count);
    position: absolute;
    background: #f0d561;
    height: 20px;
    width: 20px;
    top: -8px;
    right: -11px;
    text-align: center;
    line-height: 1.6rem;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    color: #ffffff;
    border: 1px #f0d561 solid;
  }
}

.comments .show-comments {
  cursor: pointer;
}

.comments-list-wrapper {
  display: none;

  .comments-list {
    .loading {
      text-align: center;
      margin-left: -50px;
    }
    .no-comments {
      margin-left: -23px;
    }
  }

  #comment-error {
    display: inline-block;
    margin: 5px 15px 5px 15px;
  }
}

.terms {
  th, td:not(:first-child) {
    text-align: center;
  }
  .img-small {
    width: 40px;
    height: 40px;
    border: 0 !important;
    background-color: #f5f5f5;
  }
  i {
    font-size: 18px;

    &.confirmed {
      color: #24793c;
    }
    &.not-confirmed {
      color: #e55452;
    }
  }
  &-error {
    display: none;
  }
}